import React                  from 'react'
import Img                    from "gatsby-image"
import Link                   from 'components/Link'
// import cx                     from 'utils/classnames'
// import {
//   Col,
// }                             from 'react-bootstrap'
import styles                 from './styles.module.scss'


const SquareGrid = ({ children }) => (
  <div className={styles.root}>
    {children}
  </div>
)


SquareGrid.Item = ({ image, title, url, children }) => (
  <Link className={styles.item} to={url}>
    <Img className={styles.itemImg} fluid={{ ...image, aspectRatio: 1 }} />
    <div className={styles.gridItemTitle}>{title}</div>
  </Link>
)

export default SquareGrid
