import React, { useState }  from 'react'
import { map }              from 'lodash-es'
import cx                   from 'utils/classnames'
import {
    FaMapMarkerAlt,
    FaClock,
    FaTag,
    FaThumbsUp,
}                           from 'react-icons/fa'
import {
    Container,
}                           from 'react-bootstrap'
import {
    useWindowSize,
}                           from 'utils'
import Icon                 from 'entrada-ui/Icon'
import Typography           from 'entrada-ui/Typography'
import Banner               from 'components/Banner'
import Link                 from 'components/Link'
import Seo                  from 'components/Seo'
import Slideshow            from 'components/Slideshow'
import SquareGrid           from 'components/SquareGrid'
import TourCards            from 'components/TourCards'
import TourSearch           from 'components/TourSearch'
import styles               from './styles.module.scss'

const Feature = ({ children }) => <div className={styles.feature}>{children}</div>
const FeatureIcon = ({ children }) => <Icon className={styles.featureIcon}>{children}</Icon>
const TourCard = TourCards.CardWp

const features = [{
    title: 'Tours nationwide',
    icon: <FaMapMarkerAlt />
}, {
    title: 'Easy & Free Cancellations',
    icon: <FaClock />
}, {
    title: 'Lowest Price Guarantee',
    icon: <FaTag />
}, {
    title: 'Local Customer Service & Guides',
    icon: <FaThumbsUp />
}]

export default (props) => {
    const {
        pageContext: {
            data: {
                siteMetadata,
                title,
                acf: {
                    slider,
                    related_tours,
                    destination_cards
                },
                yoast_meta
            }
        }
    } = props

    const wSize = useWindowSize()



    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            <div className={styles.hero}>
                <Slideshow
                    className={styles.slideshow}
                    // noTransition={true}
                >
                {slider.map((slide, idx) => (
                    <Slideshow.Slide key={idx}>
                        <Slideshow.Img
                            key={idx}
                            className={styles.slideImage}
                            fluid={
                                wSize.isMobile ?
                                slide.acf.mobile_image.localFile.childImageSharp.fluid :
                                slide.acf.desktop_image.localFile.childImageSharp.fluid
                            }

                        />
                        <div className={styles.slideDescription}>
                            <Typography variant="h2">{slide.title}</Typography>

                            <Typography>{slide.acf.slider_text}</Typography>
                            <Link className="btn-secondary" to={slide.url}>
                                {slide.title} Tours
                            </Link>
                        </div>
                    </Slideshow.Slide>
                ))}
                </Slideshow>

                <div className={styles.features}>
                    <Container>
                        <div className={styles.featuresList}>
                            {features.map((item, idx) => (
                            <Feature key={idx}>
                                <FeatureIcon>
                                    {item.icon}
                                </FeatureIcon>
                                <p>{item.title}</p>
                            </Feature>
                            ))}
                        </div>
                    </Container>
                </div>

                <div className={styles.homeBody}>
                    <Container className="pt-5 pb-5">
                        <Typography className="text-center" variant="h2">
                            Truly awesome tours
                        </Typography>

                        <TourCards showArrows={false}>
                        {map(related_tours, (tour, idx) => (
                            <TourCard
                                key={idx}
                                {...tour}
                            />
                        ))}
                        </TourCards>
                    </Container>

                    <Container className={cx(styles.refund, "text-center pt-5 pb-5")}>
                        <Typography className="text-center text-color-brand-color" variant="h3">
                            See NZ the awesome way
                        </Typography>
                        
                        <p>
                        Epic tours with local experts, flexible bookings and great inclusions
                        </p>

                        <Link className="btn-primary d-inline-block mt-3" to={'/about-us'}>
                            Things that make us awesome
                        </Link>
                    </Container>

                    <Container className="pt-5 pb-5">
                        <SquareGrid>
                            {destination_cards.map((destination, idx) => (
                                <SquareGrid.Item
                                    key={idx}
                                    image={destination.acf.tile_image.localFile.childImageSharp.fixed}
                                    title={destination.title}
                                    url={`/${destination.slug}`}
                                >
                                </SquareGrid.Item>
                            ))}
                        </SquareGrid>
                    </Container>

                    <div className={styles.seeCombos}>
                        <Container className="position-relative">
                            <div className={styles.seeCombosBlock}>
                                <Typography className="text-color-brand-color-2" variant="h3">
                                    Double the action
                                </Typography>
                                
                                <p>Book a great value two-tour combo and save!</p>

                                <Link className="btn-secondary d-inline-block mt-3" to={'/tour-combos'}>
                                    See all combos
                                </Link>
                            </div>
                        </Container>

                        <div className={styles.footerImage} />
                    </div>

                </div>
            </div>
        </>
    )
}
